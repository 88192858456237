// Array da tradução
let langJSON = [];

// Recupera a tradução para a linguagem atual
$.ajax({
  type: "POST",
  url: url_base + "acoes/api/get_json_lang.php",
  async: false,
  success: function (data) {
    //console.log(data);
    data = JSON.parse(data);
    // Verifica se é um objeto e se o mesmo não está vazio
    if (typeof data == "object" && !$.isEmptyObject(data)) {
      langJSON = data;
    }
  },
  error: function (xhr, type, exception) {
    console.log("Erro ao carregar o JSON da tradução.");
  },
});

function Traduz(string) {
  // Valida a string
  if (string && string != "") {
    //  Verifica se o array não está vazio e se o mesmo possui a tradução para a string passada
    if (!$.isEmptyObject(langJSON) && langJSON[string] !== undefined) {
      return langJSON[string];
    }
  }
  return string;
}
